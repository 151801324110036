



























import { Component, Vue } from 'vue-property-decorator'
import terminalModule from '@/store/terminal'

const Footer = () => import('@/views/footer.vue')

@Component({
  components: { Footer }
})
export default class LockScreen extends Vue {
  get isDemoMode() {
    return terminalModule.getters.terminal?.isDemoMode
  }
}
